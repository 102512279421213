import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { IUserDetailsWithTags } from 'interfaces/user.interface';
import SUserService from '../../../services/user/user.service';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { LoadingComponent } from 'components/loading/Loading.component';
import '../Users.scss';
import { User } from '@microsoft/microsoft-graph-types';

const TableWrapper = styled.p`
  height: 400;
  width: '50%';
  margin-top: 20px;
`;

interface IListUsers {
  loading: boolean;
  usersList: IUserDetailsWithTags[];
  onUserEdit: (user: IUserDetailsWithTags) => void;
  refreshUsersList: () => void;
}

export const ListUsers = (props: IListUsers) => {
  const { usersList, loading, onUserEdit, refreshUsersList } = props;
  const [open, setOpen] = useState(false);
  const [openVerified, setOpenVerified] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [usernameVerification, setUsernameVerification] = useState('');
  const [loggedInUser, setLoggedInUser] = useState<Partial<User>>({
    userPrincipalName: '',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleClickOpen = (UserId: number) => {
    setSelectedUserId(UserId);
    setOpen(true);
  };

  const handleClickOpenVerified = () => {
    if (loggedInUser.userPrincipalName == usernameVerification) {
      setOpenVerified(true);
      setUsernameError(false);
    } else {
      setUsernameError(true);
    }
  };

  const handleClose = () => {
    setSelectedUserId(null);
    setUsernameError(false);
    setUsernameVerification('');
    setOpen(false);
  };
  const handleCloseVerified = () => {
    setSelectedUserId(null);
    setUsernameVerification('');
    setOpenVerified(false);
    handleClose();
  };

  const handleDeleteUser = (id: number | null) => {
    if (!deleteLoading && id) {
      setDeleteLoading(true);
      SUserService.deleteUser(id)
        .then(() => {
          setDeleteLoading(false);
          refreshUsersList();
          showSnackbar('User deletion successful!', 'success');
          handleCloseVerified();
        })
        .catch(() => {
          setDeleteLoading(false);
          handleCloseVerified();
          showSnackbar('User deletion failed!', 'error');
        });
    }
  };

  useEffect(() => {
    SUserService.getUser().then((user: User) => {
      setLoggedInUser(user);
    });
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      {loading ? (
        <div className="loading-container">
          <LoadingComponent />
        </div>
      ) : (
        <>
          {usersList.length ? (
            <Paper sx={{ width: '100%', mb: 2 }}>
              <TableWrapper>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow selected>
                        <TableCell
                          align="left"
                          sx={{ fontFamily: 'Inria Sans' }}
                        >
                          Id
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontFamily: 'Inria Sans' }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontFamily: 'Inria Sans' }}
                        >
                          Email Address
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontFamily: 'Inria Sans' }}
                        >
                          Admin
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontFamily: 'Inria Sans' }}
                        >
                          Locked
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontFamily: 'Inria Sans' }}
                        >
                          Super User
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontFamily: 'Inria Sans' }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {usersList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user: IUserDetailsWithTags) => (
                          <TableRow
                            key={user.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              font: 'Inria Sans',
                            }}
                          >
                            <TableCell
                              align="left"
                              sx={{ fontFamily: 'Inria Sans' }}
                            >
                              {user.id}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontFamily: 'Inria Sans' }}
                            >
                              {user.description}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontFamily: 'Inria Sans' }}
                            >
                              {user.emailAddress}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontFamily: 'Inria Sans' }}
                            >
                              {user.isAdmin ? 'yes' : 'no'}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontFamily: 'Inria Sans' }}
                            >
                              {user.isLocked ? 'yes' : 'no'}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ fontFamily: 'Inria Sans' }}
                            >
                              {user.isSuperUser ? 'yes' : 'no'}
                            </TableCell>
                            <TableCell align="left">
                              <IconButton
                                onClick={() => {
                                  onUserEdit(user);
                                }}
                              >
                                <FaEdit color="blue" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  handleClickOpen(user.id);
                                }}
                              >
                                <FaRegTrashAlt color="red" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={usersList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Please enter your email address (current signed in user)
                    </DialogContentText>
                    <TextField
                      size="medium"
                      type="string"
                      value={usernameVerification}
                      onChange={(event) => {
                        setUsernameVerification(event.target.value);
                      }}
                      error={usernameError}
                      placeholder="Email"
                      helperText={
                        usernameError
                          ? 'Please enter your correct email to proceed!'
                          : ''
                      }
                      inputProps={{
                        style: {
                          padding: '14px',
                          width: '18rem',
                        },
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                      color="error"
                      onClick={handleClickOpenVerified}
                      autoFocus
                    >
                      Verify
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  open={openVerified}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you want to delete this User permanently ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseVerified}>Cancel</Button>
                    <Button
                      color="error"
                      onClick={() => handleDeleteUser(selectedUserId)}
                      autoFocus
                      disabled={deleteLoading}
                    >
                      {deleteLoading ? (
                        <CircularProgress size="20px" />
                      ) : (
                        'Confirm'
                      )}
                    </Button>
                  </DialogActions>
                </Dialog>
              </TableWrapper>
            </Paper>
          ) : (
            <div className="no-user-label">
              There is no user associated with the selected location.
            </div>
          )}
        </>
      )}
    </div>
  );
};
